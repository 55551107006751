<template>
  <v-container >
      <v-row>
        <!-- Image Container -->
        <v-col
          cols="12"
          md="12"
          order="1"
          order-md="1"
        >
          <template>
            <v-card class="color-card" flat>
              <v-card-title class="text-center justify-center py-6">
                <v-list-item-avatar tile size="90" width="220" height="135">
                  <v-img src="../assets/logo.png" class="logo-image"></v-img>
                </v-list-item-avatar>
              
              </v-card-title>

              <v-tabs
                v-model="tab"
                background-color="transparent"
                grow
                color="blue"
              >
                <v-tab
                  v-for="item in items"
                  :key="item"
                >
                  {{ item }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab" class="tabs">
                <v-tab-item>
                  <br>
                  <v-icon large color="blue" style="margin-right: 5%;">mdi-console-line</v-icon>
                  <h4>Processamento  de  Alto  Desempenho</h4>
                  <span>Ambientes  próprios  para  execução  de bancos de dados, correio eletrônico, web, ERP, dentre outros. <br>
                    Dimensionamento por tipo de  aplicação  ou  tecnologia  dos  servidores,  instalação  de  ambientes,  suporte  on-site  e avaliação de performance.
                  </span><br><br>

                  <v-icon large color="blue" style="margin-right: 5%;">mdi-desktop-mac</v-icon>
                  <h4>Soluções  de  Alta  Disponibilidade  e  Cluster </h4>
                  <span>Ambientes  disponíveis  no  mínimo 99,9%  do  tempo,  capazes  de  compartilhar  recursos. <br>
                   Dimensionamento,  instalação, suporte on-site e avaliação de performance.
                  </span><br><br>
                  
                  <v-icon large color="blue" style="margin-right: 5%;">mdi-database-outline</v-icon> 
                  <h4>Soluções  de  Armazenamento </h4>
                  <span>(ambientes  centralizados  de  armazenamento  em discos – SAN e NAS - e em fitas - backup.  <br>
                   D imensionamento, instalação, suporte on-site e avaliação de performance.
                  </span><br><br>
                </v-tab-item>

                <v-tab-item>
                  <br>
                  <v-icon large color="blue" style="margin-right: 5%;">mdi-book-check-outline</v-icon>
                  <h4>Consultoria em Gerenciamento do Nível de Serviço de Sistemas</h4>
                  <span>Propósito de monitorar a disponibilidade e desempenho dos elementos de infraestrutura (servidores, middleware ,switches , roteadores, 
                    load balancers, firewalls, bancos  de  dados,  e  storage)  e  processos  de  negócio  (transações  desempenhadas  por aplicações de negócio)
                    e estruturação de indicadores de performance.
                  </span><br><br>

                  <v-icon large color="blue" style="margin-right: 5%;">mdi-book-check-outline</v-icon>
                  <h4>Consultoria na Construção de Condições para a Detecção de Eventos</h4>
                  <span>De acordo com a necessidade de gerenciamento dos sistemas gerando os alertas necessários. Como exemplo, criação de alertas quando 
                    limites são excedidos, quando transações travam ou quando ocorrem excessivos atrasos no acesso ao banco de dados.
                  </span><br><br>
                  
                  <v-icon large color="blue" style="margin-right: 5%;">mdi-book-check-outline</v-icon> 
                  <h4>Consultoria no Gerenciamento e Monitoramento de Aplicações</h4>
                  <span>Processos de negócio, sistemas e redes de computadores, implementando  através  de  monitoramento central  com  software  de   
                    monitoramento instalado e os respectivos agentes de monitoramento distribuídos.
                  </span><br><br>

                  <v-icon large color="blue" style="margin-right: 5%;">mdi-book-check-outline</v-icon> 
                  <h4>Consultoria Focando a Disponibilização de Dados Estatísticos de Monitoramento</h4>
                  <span>Análise instantânea, identificada pelo valor coletado pelo monitor<br>
                      Análise  média  ou  condensada,  identificada  por  uma  média  horária  ou porcentagem dos eventos e valores coletados
                  </span><br><br>

                  <v-icon large color="blue" style="margin-right: 5%;">mdi-book-check-outline</v-icon> 
                  <h4>Consultoria Focando a Disponibilização de Dados Georeferenciados</h4>
                  <span>Referenciar dados ou objetos com base em sua localização, ou seja, tratar dados que contém correspondência com objeto real: <br>
                    <strong>Estático (absoluto):</strong>Permite a monitoração da disponibilidade; <br>
                    <strong>Baseline:</strong>Permite a monitoração e acompanhamento dos indicadores de desempenho, gerado automaticamente; <br>
                    <strong>Inteligente:</strong>Permite  filtrar  os  que  ocorrem  regularmente  por  meio  do baseline , configuração do horário de coleta 
                    e métricas absolutas definidas pelo administrador;
                  </span><br><br>
                </v-tab-item>

                <v-tab-item>
                  <br>
                  <v-icon large color="blue" style="margin-right: 5%;">mdi-console-line</v-icon>
                  <h4>BlockChain</h4>
                  <span>Desenvolvimento de soluções utilizando tecnologia BlockChain para rastreabilidade de informações coletadas de base de dados variadas.
                  </span><br><br>

                  <v-icon large color="blue" style="margin-right: 5%;">mdi-desktop-mac</v-icon>
                  <h4>Consultoria no desenvolvimento de Análise Correlacional de Eventos</h4>
                  <span>De forma a produzir diagnóstico de problemas exclusivamente relacionados aos dados coletados na
                    integração com o ambiente operacional de Tecnologia da Informação em tempo real por meio de eventos.
                  </span><br><br>
                  
                  <v-icon large color="blue" style="margin-right: 5%;">mdi-database-outline</v-icon> 
                  <h4>Consultoria na Criação de Política de Requisitos de Mudança</h4>
                  <span>Incluindo as ações de reparo com as respectivas tarefas para retornar a configuração do elemento igual ao padrão estabelecido no “Baseline”,
                    gerando automaticamente as instruções para desfazer a modificação.
                  </span><br><br>

                   <v-icon large color="blue" style="margin-right: 5%;">mdi-equalizer</v-icon> 
                  <h4>Consultoria na Determinação de Saúde Global de uma Transação</h4>
                  <span>Utilizando métricas de disponibilidade, precisão e desempenho. 
                  </span><br><br>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </template>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    tab: null,
    items: [
      'Hardware', 'Gestão', 'Software',
    ],
  }),
}
</script>
<style lang="scss" scoped>
h4{
  font-family: 'Montserrat';
  font-size: 20px;
  line-height: 160%;

  color: #414148;
}

.app-subtitle{
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 700 !important;
  line-height: 120% !important;

  color: #414148 !important;
}

span {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: justify;

  /* or 24px */

  color: #414148;
}

.container-text {
  margin-left: 5%;
}

@media only screen and (max-device-width: 700px) {
  span {
    text-align: left !important;
  }
  // .tabs {
  //  
  // }
}
</style>>

