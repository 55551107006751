<template>
  <v-container id="page-landing" style="height: 80vh">
    <v-row>
      <!-- Form Contaact -->
      <v-col
        cols="12"
        md="5"
      >
        <template>
          <p class="title-app">Contato</p>   
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            action="https://api.staticforms.xyz/submit" 
            method="post"
          >
            <!-- <input type="hidden" name="accessKey" value="4f7946e3-8d4d-401d-b724-0fda99fe8282"> -->
            <!-- <input type="hidden" name="redirectTo" value="https://www.smitt.com.br"> -->
            <v-text-field
              v-model="contact.name"
              label="Nome"
              color="blue darken-2"
              name="name"
              :rules="[rules.required]"
              required
            ></v-text-field>

            <v-text-field
              v-model="contact.email"
              label="E-mail"
              placeholder="exemplo@gmail.com"
              color="blue darken-2"
              name="email"
              :rules="[rules.required, rules.email]"
              required
            ></v-text-field>

            <v-text-field
              v-model="contact.cell"
              label="Celular"
              placeholder="(61) 98282-8282"
              color="blue darken-2"
              name="phone"
              type="tel"
              id="telefone"
              v-mask="['(##) ####-####', '(##) #####-####']"
              :rules="[rules.required]"
              required
            ></v-text-field>
            
            <v-textarea
              v-model="contact.message"
              label="Mensagem"
              color="blue darken-2"
              name="message"
            >
            </v-textarea>

            <v-dialog
              v-model="dialog"
              persistent
              width="450"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :loading="loading"
                  color="blue darken-2" 
                  class="mr-4"
                  dark
                  @click="createTrial"
                  :disabled="!valid"
                  v-bind="attrs"
                  v-on="on"
                >
                  Enviar
                </v-btn>
              </template>
              <v-card justify="center" style="justify-content: center;">
                <v-card-title class="justify-center">
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="teal"
                  ></v-progress-circular>
                  <v-icon v-else-if="error" color="orange" x-large>mdi-alert-circle</v-icon>
                  <v-icon v-else color="green" x-large>mdi-check-circle</v-icon>
                </v-card-title>
                <v-card-text class="text-center">
                  <span class="body-1" v-if="error" v-text="error"/>
                  <span class="body-1" v-else>
                    Seus dados de acesso foram enviados para nossa central!
                  </span>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="grey"
                    text
                    @click="dialog = false"
                    small
                  >
                    Fechar
                  </v-btn>
                  <v-btn
                    color="green"
                    text
                    @click="dialog = false"
                    small
                    to="/"
                  >
                    Voltar a pagina inicial
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-form>
        </template>
      </v-col>
      

      <!-- Side illustration -->
      <template>
        <v-spacer></v-spacer>
        <v-row>
          <v-col
            cols="12"
            md="5"
          >
            <div class="illustration"  v-if="!$vuetify.breakpoint.mobile">
                <img src="@/assets/logo.png" alt="SARKO" style="cursor: pointer; margin-left: 20%;">
            </div>
          </v-col>
        </v-row>
      </template>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    contact: {
      email: '',
      name: '',
      cell: '',
      message: '',
    },
    Send: false,
    valid: true,
    errors: {},
    rules: {
      required: value => !!value || 'Campo obrigatório',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'E-mail invlálido'
      },
    },
    confirm: false,
    dialog: false,
    loading: false,
    error: '',
  }),
  // mounted(){
  // },
  methods: {
     createTrial(){
      if(this.valid){
        this.sendRequest()
      }else{
        this.error = 'Favor preencher todos os campos obrigatórios'
      }
    },
    sendRequest() {
      this.loading = true

      const formData = new FormData()

      const data = {
        'mauticform[nome]': this.contact.name,
        'mauticform[email]': this.contact.email,
        'mauticform[telefone_de_contato]': this.contact.cell,
        'mauticform[mensagem]': this.contact.message,
        'mauticform[pagina_de_origem]': window.location.origin,
        'mauticform[formId]': '1',
        'mauticform[formName]': 'formulariodecontato',
        'mauticform[submit]': '', 
        'mauticform[return]': '',
        'formId': '1',
      }

      for (const key in data) {
        formData.append(key, data[key])
      }

      const config = {
        method: 'POST',
        mode: 'cors',
        cache: 'default',
        body: formData,
        redirect: 'manual'
      }

      fetch('https://mautic.spezi.com.br/form/submit?formId=1', config).then(response => {
        this.loading = false
        if(response.ok) {
          console.log('Formulário enviado com sucesso!')
        } else {
          this.error = 'Erro ao enviar dados, tente novamente mais tarde'
        }
      })
      .catch(error =>  {
        this.loading = false
        this.error = 'Erro ao enviar dados, tente novamente mais tarde'
        console.log('There has been a problem with your fetch operation: ' + error.message);
      });
    }
  },
}
</script>

<style>
.v-textarea textarea {
  max-height: 70px;
  margin: 0;
}

.title-app {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 700 !important;
  font-size: 32px !important;
  line-height: 110% !important;

  color: #212121 !important;
}

</style>
